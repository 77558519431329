import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";



// Async thunk for fetching links
export const getLinksContent = createAsyncThunk(
  "links/getContent",
  async (deviceID, { rejectWithValue }) => {
    try {
      const response = await axios.get("/api/links/getLinks/"+deviceID);
      return response.data; // Ensure this matches your API response structure
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to fetch links");
    }
  }
);

// Async thunk for creating a new link
export const createLink = createAsyncThunk(
  "links/create",
  async (newLinkObj, { rejectWithValue }) => {
    try {
      const response = await axios.post("/api/links/createLink", newLinkObj);
      return response.data; // Return the created link data
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to create link");
    }
  }
);

// Async thunk for updating an existing link
export const updateLink = createAsyncThunk(
  "links/update",
  async ({ id, updatedLink }, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/api/links/${id}`, updatedLink);
      return response.data; // Return the updated link data
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to update link");
    }
  }
);

// Async thunk for deleting a link
export const deleteLink = createAsyncThunk(
  "links/deleteLink",
  async (id, { rejectWithValue }) => {
    try {
      await axios.delete(`/api/links/${id}`);
      return id; // Return the id of the deleted link
    } catch (error) {
      return rejectWithValue(error.response?.data || "Failed to delete link");
    }
  }
);


// Slice definition
export const linksSlice = createSlice({
  name: "links",
  initialState: {
    isLoading: false,
    links: [],
    selectedDevice: null,
    error: null,
    streamURL: "",
  },
  reducers: {
    // Reducer to select a device
    setSelectedDevice: (state, action) => {
      state.selectedDevice = action.payload; // Set the selected device
    },
  },
  extraReducers: (builder) => {
    builder
      // Get Links
      .addCase(getLinksContent.pending, (state) => {
        state.isLoading = true;
        state.error = null; // Reset error on new fetch
      })
      .addCase(getLinksContent.fulfilled, (state, action) => {
        state.links = action.payload.data;
        state.streamURL = action.payload.url_prefix; // Populate links with fetched data
        state.isLoading = false;
      })
      .addCase(getLinksContent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message; // Capture error message
      })

      // Create Link
      .addCase(createLink.pending, (state) => {
        state.isLoading = true; // Set loading when creating a link
        state.error = null; // Reset error
      })
      .addCase(createLink.fulfilled, (state, action) => {
        const link ={...action.payload.link ,linkUrl :action.payload.linkUrl}
        state.links.push(link); // Add new link to state
        state.isLoading = false; // Set loading to false
      })
      .addCase(createLink.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message; // Capture error message
      })

      // Update Link
      .addCase(updateLink.pending, (state) => {
        state.isLoading = true; // Set loading when updating a link
        state.error = null; // Reset error
      })
      .addCase(updateLink.fulfilled, (state, action) => {
        const index = state.links.findIndex(
          (link) => link.id === action.payload.id
        );
        if (index !== -1) {
          state.links[index] = action.payload; // Update the link in state
        }
        state.isLoading = false; // Set loading to false
      })
      .addCase(updateLink.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message; // Capture error message
      })

      // Delete Link
      .addCase(deleteLink.pending, (state) => {
        state.isLoading = true; // Set loading when deleting a link
        state.error = null; // Reset error
      })
      .addCase(deleteLink.fulfilled, (state, action) => {
        state.links = state.links.filter((link) => link._id !== action.payload); // Remove deleted link
        state.isLoading = false; // Set loading to false
      })
      .addCase(deleteLink.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message; // Capture error message
      });
  },
});

// Export the action for setting the selected device
export const { setSelectedDevice } = linksSlice.actions;

// Export the reducer
export default linksSlice.reducer;
