import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'



export const getDevicesContent = createAsyncThunk(
  'devices/getContent',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/api/devices/getDevices');
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to fetch devices');
    }
  }
);

export const addDevicesContent = createAsyncThunk(
  'devices/addContent',
  async (device, { rejectWithValue }) => {
    try {
      const response = await axios.post('/api/devices/addDevice', device);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to add device');
    }
  }
);

export const updateDevicesContent = createAsyncThunk(
  'devices/updateContent',
  async (device, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/api/devices/editDevice/${device._id}`, device);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to update device');
    }
  }
);

export const deleteDevicesContent = createAsyncThunk(
  'devices/deleteContent',
  async (deviceId, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/api/devices/deleteDevice/${deviceId}`);
      return { data: response.data, deviceId };
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Failed to delete device');
    }
  }
);

export const devicesSlice = createSlice({
  name: 'devices',
  initialState: {
    isLoading: false,
    devices: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDevicesContent.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getDevicesContent.fulfilled, (state, action) => {
        state.devices = action.payload
        state.isLoading = false
      })
      .addCase(getDevicesContent.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(addDevicesContent.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addDevicesContent.fulfilled, (state, action) => {
        state.devices.push(action.payload.data)
        state.isLoading = false
      })
      .addCase(addDevicesContent.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateDevicesContent.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateDevicesContent.fulfilled, (state, action) => {
        const index = state.devices.findIndex(device => device._id === action.payload.data._id)
        if (index !== -1) {
          state.devices[index] = action.payload.data
        }
        state.isLoading = false
      })
      .addCase(updateDevicesContent.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(deleteDevicesContent.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteDevicesContent.fulfilled, (state, action) => {
        state.devices = state.devices.filter(device => device._id !== action.payload.deviceId)
        state.isLoading = false
      })
      .addCase(deleteDevicesContent.rejected, (state) => {
        state.isLoading = false
      })
  }
})

export default devicesSlice.reducer