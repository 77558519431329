import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { showNotification } from "../common/headerSlice";
// Async thunk to fetch users
export const deleteUserContent = createAsyncThunk(
  "users/deleteContent",
  async (userId, { rejectWithValue }) => {
    try {
      await axios.delete(`/api/users/delete/${userId}`);
      return userId; // Return userId for local state update
    } catch (error) {
      // Handle error and return a meaningful message
      return rejectWithValue(error.response?.data || "Failed to delete user");
    }
  }
);



export const getUsersContent = createAsyncThunk(
  "users/getContent",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/api/users");
      return response.data; // Assuming response.data contains the user data
    } catch (error) {
      // Handle error and return a meaningful message
      return rejectWithValue(error.response?.data || "Failed to fetch users");
    }
  }
);

// Async thunk to add a user
export const addUserContent = createAsyncThunk(
  "users/addContent",
  async (newUser, { rejectWithValue }) => {
    try {
      const response = await axios.post("/api/users/adduser", newUser);
      return response.data; // Assuming response.data contains the added user
    } catch (error) {
      // Handle error and return a meaningful message
      return rejectWithValue(error.response?.data || "Failed to add user");
    }
  }
);

// Async thunk to update a user
export const updateUserContent = createAsyncThunk(
  "users/updateContent",
  async (object, { rejectWithValue }) => {
    try {
      const response = await axios.put(`/api/users/edit/${object._id}`, object.newUserObj);
      return response.data; // Assuming response.data contains the updated user
    } catch (error) {
      // Handle error and return a meaningful message
      return rejectWithValue(error.response?.data || "Failed to update user");
    }
  }
);


export const usersSlice = createSlice({
  name: "users",
  initialState: {
    isLoading: false,
    users: [],
    error: null,
  },
  reducers: {
 
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsersContent.pending, (state) => {
        state.isLoading = true;
        state.error = null; // Reset error on new request
      })
      .addCase(getUsersContent.fulfilled, (state, action) => {
        state.users = action.payload;
        state.isLoading = false;
      })
      .addCase(getUsersContent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message; // Capture error message
      })
      .addCase(deleteUserContent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteUserContent.fulfilled, (state, action) => {
        // Remove the deleted user from the state
        state.users = state.users.filter(user => user._id !== action.payload);
        state.isLoading = false;
      })
      .addCase(deleteUserContent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message; // Capture error message
      })
      .addCase(addUserContent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addUserContent.fulfilled, (state, action) => {
        // Add the new user to the state
        state.users.push(action.payload.data[0]);
        state.isLoading = false;
      })
      .addCase(addUserContent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message; // Capture error message
      })
      .addCase(updateUserContent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserContent.fulfilled, (state, action) => {
        // Update the user in the local state
        const index = state.users.findIndex(user => user._id === action.payload._id);
        if (index !== -1) {
          state.users[index] = action.payload; // Replace the old user with the updated user
        }
        state.isLoading = false;
      })
      .addCase(updateUserContent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message; // Capture error message
      });
  },
});

// Export actions and reducer
export const { addNewUser } = usersSlice.actions;
export default usersSlice.reducer;
