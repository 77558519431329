import { configureStore } from '@reduxjs/toolkit'
import headerSlice from '../features/common/headerSlice'
import modalSlice from '../features/common/modalSlice'
import rightDrawerSlice from '../features/common/rightDrawerSlice'
import leadsSlice from '../features/leads/leadSlice'
import usersSlice from '../features/users/usersSlice'
import devicesSlice  from '../features/devices/deviceSlice'
import notificationsSlice from '../features/common/notifications/notificationsSlice'
import  linksSlice  from '../features/device-preview/linksSlice'
import authSlice from './authSlice'
const combinedReducer = {
  header : headerSlice,
  rightDrawer : rightDrawerSlice,
  modal : modalSlice,
  lead : leadsSlice ,
  user : usersSlice ,
  device : devicesSlice ,
  notifications: notificationsSlice,
  link : linksSlice ,
  auth: authSlice
  
}

export default configureStore({
    reducer: combinedReducer
})